import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import SVGIcon from "../components/common/SVGIcon"
import Frame from "../components/common/frame"
import Navigation from "../components/navigation"
import { downloadAsset } from "../components/utility/asset_download"
import Slide from "../components/utility/slide_content"
import "../styles/templates/ebooktemp.scss"
import Popper from "./../assets/images/party-popper.svg"

const EbookTemp = ({ pageContext }) => {
  const [success, setSuccess] = useState(false)
  const {
    pageName,
    categoryDropdown,
    pageSlug,
    title,
    downloadEbook,
    chapterNumber,
    chapterContent,
    list,
    successMessage,
    seo,
    image,
  } = pageContext.bookdata
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },

    {
      name: "mobilephone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
    },
  ]
  const currentLocation = useLocation()
  let path = currentLocation.href

  const handleAfterSubmit = (res, item) => {
    setSuccess(true)
    downloadAsset(downloadEbook?.url, title)
  }
  // const handleSubmit = items => {
  //   const formData = [...items]
  //   formData.push({ name: "template_name", value: title })
  //   var fullname = formData[0].value.trim()
  //   if (fullname.includes(" ")) {
  //     //split Name if includes a empty space
  //     var fname = fullname.substr(0, fullname.indexOf(" "))
  //     var lname = fullname.substr(fullname.indexOf(" ") + 1)
  //     formData[0].value = fname
  //   } else {
  //     //else set Name as first_name
  //     fname = fullname
  //     lname = ""
  //   }
  //   formData[0].value = fname
  //   formData.push({ name: "lastname", value: lname })

  //   var formStatus = handleFormSubmit(
  //     formData,
  //     process.env.HUBSPOT_TEMPLATE_FORM_ENDPOINT,
  //     path,
  //     `${title} - Superops`,
  //     null,
  //     null,
  //     startdownload
  //   )
  // }
  // function startdownload() {
  //   downloadAsset(downloadEbook?.url, title)
  //   setSuccess(true)
  // }

  const pageCategory =
    categoryDropdown === "One_million" ? "templates" : "books"
  return (
    <div className="template-feature">
      <>
        <Frame seo={seo[0]}>
          <header>
            <Navigation color={"#ffffff"} page="Home" />
          </header>

          <section className="ebook-download">
            <Container className="Layout-container">
              <Row>
                <Col lg={6}>
                  <div className="pg-left">
                    <Slide delay="200">
                      <div className="mb24">
                        <Link
                          className="no-deco navigate-btn cp d-flex gap-2 align-items-center d-dsk"
                          to={`/resources/${pageCategory}`}
                        >
                          <SVGIcon
                            name="hypertext-arrow-right"
                            className="back-arrow"
                          />
                          {pageCategory}
                        </Link>
                      </div>
                    </Slide>
                    <Slide delay="200">
                      <h3 className="main-title mb24 fw-bold lh-56">{title}</h3>
                    </Slide>
                    <Slide delay="300">
                      <p className="p16 mw-90 mb38 fw-normal text-grey">
                        {parse(chapterContent?.html)}
                      </p>
                    </Slide>
                    <Slide delay="300">
                      {list.map(point => {
                        return (
                          <div className="d-flex gap-3">
                            <SVGIcon
                              name="ebook/Done-icon"
                              className="prelative point-icon"
                            />
                            <p className="p16 mb24 w-75 fw-normal text-grey">
                              {point}
                            </p>
                          </div>
                        )
                      })}
                    </Slide>
                  </div>
                </Col>
                <Col lg={6}>
                  <Link
                    className="no-deco navigate-btn cp d-flex gap-2 align-items-center d-mob"
                    to="/resources/templates"
                  >
                    <SVGIcon
                      name="hypertext-arrow-right"
                      className="back-arrow"
                    />
                    templates
                  </Link>
                  <Slide delay="400">
                    <div className="pg-right prelative">
                      <div className="bk-form-container w-100">
                        <Container className="Layout-container">
                          <div className="img-wrap position-relative mb-3">
                            <center>
                              <img
                                src={image.url}
                                className="book-img br-10"
                                alt="book"
                              />
                            </center>
                          </div>
                          {/* Need to change to formBuilder v2 */}
                          {!success ? (
                            <FormBuilder
                              id="ebook-form"
                              formClassName="forms"
                              errorClassName="error-message"
                              inputErrorClassName="error-active"
                              data={formdata}
                              buttonText={
                                success ? "DOWNLOADED" : "DOWNLOAD NOW"
                              }
                              buttonClassName="primary"
                              buttonId="downloadSubmitButton"
                              url={path}
                              extradata={{
                                name: "template_name",
                                value: title,
                              }}
                              endpoint={
                                process.env.HUBSPOT_TEMPLATE_FORM_ENDPOINT
                              }
                              afterSubmit={handleAfterSubmit}
                              IpStackData
                              formName={`${title} - Superops`}
                              // url={`${title} - Superops`}
                            />
                          ) : (
                            <>
                              <div className="text-center mb24">
                                <img
                                  src={Popper}
                                  alt="popper"
                                  className="mb24 popper-img"
                                />

                                <h3 className="mb24 fw-bold">Success!</h3>

                                <div className="mb50 text-grey">
                                  <p className="p16 ">{successMessage}</p>
                                </div>
                              </div>
                            </>
                          )}
                        </Container>
                      </div>
                    </div>
                  </Slide>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="mt100"></section>
        </Frame>
      </>
    </div>
  )
}

export default EbookTemp
